<template>
    <div>
        <CompTable ref="comp_table" isSelector :title="title + '审核 ( ' + date + ' )'" :columns="columns" :table-api="tableApi" :dataBefore="onSearchDataBefore" @on-load="onLoadTableData">
            <template #search="evt">
                <RadioGroup v-model="evt.search.onlineStatus" type="button" v-if="type === 'community'">
                    <Radio label="2">试用社区</Radio>
                    <Radio label="1">示范社区</Radio>
                    <Radio label="3">非试用社区</Radio>
                </RadioGroup>
                <RadioGroup v-if="evt.search.onlineStatus == 1 && type === 'community'" v-model="evt.search.onlineType" type="button">
                    <Radio label="1">市级示范社区</Radio>
                    <Radio label="2">区级示范社区</Radio>
                    <Radio label="3">街镇级示范社区</Radio>
                </RadioGroup>
                <Input v-model="evt.search.name" placeholder="机构名称（当前页）"></Input>
                <Checkbox border @on-change="evt.search.failure = $event ? 1 : ''">未审核或未通过</Checkbox>
            </template>
            <template #operate>
                <Button type="primary" @click="onSubmitBatch">批量通过</Button>
            </template>
        </CompTable>

        <Audit ref="audit" :ids="ids" @on-change="onChangeData"></Audit>
        <AuditV2 ref="audit_v2" :ids="ids" @on-change="onChangeData"></AuditV2>
    </div>
</template>

<script>
import Audit from "./audit.vue"
import AuditV2 from "./audit_v2.vue"

import CompTable from "@/views/jointly/components/comp-table.vue"
import Request from "../jointly/utils/request"
import Type from "./utils/type"

export default {
    components: {
        Audit,
        AuditV2,
        CompTable,
    },

    data() {
        let type = new Type()

        return {
            title: type.getTitle(),
            // 周报类型
            type: type.get(),
            tableApi: `/gateway/api/symanage/pc/report/listReportFormDetailPage?itemId=${this.$core.getUrlParam("i")}`,
            date: decodeURIComponent(this.$core.getUrlParam("d")),
            // 详情 ID 集合
            ids: null,
            columns: [
                {
                    title: "机构",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "审核状态",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        let status = parseInt(params.row.status || "0")

                        return h(
                            "p",
                            {
                                style: {
                                    color: status === 1 ? "" : "red",
                                },
                            },
                            ["待审核", "审核通过", "审核失败", "锁定", "隐藏"][status] || "-"
                        )
                    },
                },
                {
                    title: "备注",
                    minWidth: 400,
                    key: "remark",
                    align: "left",
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 180,
                    render: (h, params) => {
                        const res = [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: (params.row.status || 0) == 1 ? "" : "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.audit.load(params.row.detailId)
                                        },
                                    },
                                },
                                (params.row.status || 0) == 1 ? "查看" : "审核"
                            ),
                        ]

                        if (type.get() === "zone") {
                            res.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: (params.row.status || 0) == 1 ? "" : "info",
                                            size: "small",
                                        },
                                        style: "margin:0 3px",
                                        nativeOn: {
                                            click: () => {
                                                this.$refs.audit_v2.load(params.row.detailId)
                                            },
                                        },
                                    },
                                    ((params.row.status || 0) == 1 ? "查看" : "审核") + "示范社区"
                                )
                            )
                        }

                        return res
                    },
                },
            ],
        }
    },

    methods: {
        onLoadTableData(evt) {
            let name = this.$refs.comp_table.search_data?.name
            if (name) {
                for (let i = 0, l = evt.value; i < l.length; i++) {
                    let v = l[i]

                    if (v.orgName.indexOf(name.trim?.() || name) === -1) {
                        l.splice(i, 1)
                        i--
                    }
                }
            }
            this.tableData = evt.value
            this.ids = evt.value?.map(item => item.detailId)
        },

        onSubmitBatch() {
            let selection = this.$refs.comp_table.getSelection()
            if (!selection || selection.length == 0) {
                return this.$Message.warning("请选择需要审核的周报")
            }
            // 提交审核
            this.onSubmitAudit(selection.map(item => item.detailId))
        },

        /**
         * 提交审核
         */
        onSubmitAudit(ids) {
            Request.post(
                "/gateway/api/symanage/pc/report/weeklyReportReview",
                {
                    detailId: ids instanceof Array ? ids.join(",") : ids,
                    status: 1,
                    remark: "",
                },
                {
                    success: "提交审核成功",
                    fail: "提交审核失败",
                }
            ).then(() => {
                if (typeof ids === "string") {
                    ids = [ids]
                }
                // 更新数据
                for (let i = 0, l = this.tableData; i < l?.length; i++) {
                    let v = l[i]

                    if (ids.indexOf(v.detailId) > -1) {
                        v.status = 1
                        v.remark = ""
                    }
                }
            })
        },

        onSearchDataBefore(data) {
            if (data.onlineStatus != 1) {
                delete data.onlineType
            }
            return data
        },

        onChangeData(evt) {
            let data = evt.value

            for (let i = 0, l = this.tableData; i < l?.length; i++) {
                let v = l[i]

                if (v.detailId === data.id) {
                    v.status = data.status
                    v.remark = data.remark
                    break
                }
            }
        },
    },
}
</script>

<style></style>
